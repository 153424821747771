import {Component, OnInit} from '@angular/core';
import {MenuItem} from 'primeng/api';
import {LayoutService} from './service/app.layout.service';
import {TranslateService} from "@ngx-translate/core";
import {GlobalService} from "../services/global.service";

@Component({
  selector: 'app-menu',
  templateUrl: './app.menu.component.html'
})
export class AppMenuComponent implements OnInit {
  
  model: any[] = [];
  
  model2: any[] = [];
  
  isAdmin = false;
  
  constructor(
    public layoutService: LayoutService,
    private translate: TranslateService,
    public globalService: GlobalService,
  ) {
  }
  
  ngOnInit() {
    this.model = [
      {
        label: this.translate.instant('menu.dashboard'),
        icon: 'pi pi-home',
        routerLink: ['/']
      },
      {separator: true},
      {
        label: this.translate.instant('menu.orderGoods'),
        /*icon: 'pi pi-book',*/
        items: [
          {
            label: this.translate.instant('menu.myDemandOrder'),
            /*icon: 'pi pi-shopping-cart',*/
            items: [
              {
                label: this.translate.instant('menu.demandOrderGoods'),
                icon: 'pi pi-shopping-cart',
                routerLink: ['/order/demand']
              },
              {
                label: this.translate.instant('menu.myInitDemandOrder'),
                icon: 'pi pi-external-link',
                routerLink: ['/order/demand/my_init']
              },
              /*{
                label: this.translate.instant('menu.myReplyDemandOrder'),
                icon: 'pi pi-reply',
                routerLink: ['/order/demand/my_reply']
              },
              {
                label: this.translate.instant('menu.myPublishDemandOrder'),
                icon: 'pi pi-send',
                routerLink: ['/order/demand/my_publish']
              },*/
              {
                label: this.translate.instant('menu.myPublishSummary'),
                icon: 'pi pi-send',
                routerLink: ['/order/demand/my_publish_summary']
              },
              {
                label: this.translate.instant('menu.myAllMyPublishDemandOrder'),
                icon: 'pi pi-calendar',
                routerLink: ['/order/demand/my_all_publish']
              }
            ]
          },
          {
            label: this.translate.instant('menu.demandOrderGroup'),
            icon: 'pi pi-list',
            routerLink: ['order/demand/demand_order_group']
          },
          {
            label: this.translate.instant('menu.demandInvoice'),
            icon: 'pi pi-book',
            routerLink: ['order/demand/my_demand_invoice']
          }
        ]
      },
      {separator: true},
      {
        label: this.translate.instant('menu.supplyGoods'),
        /*icon: 'pi pi-truck',*/
        items: [
          {
            label: this.translate.instant('menu.replyDemandOrder'),
            /*icon: 'pi pi-reply',*/
            items: [
              {
                label: this.translate.instant('menu.replyDemandOrder.notReply'),
                icon: 'pi pi-reply',
                routerLink: ['/order/demand/publish']
              },
              {
                label: this.translate.instant('menu.replyDemandOrder.notConfirm'),
                icon: 'pi pi-check',
                routerLink: ['/order/demand/reply']
              },
              {
                label: this.translate.instant('menu.replyDemandOrder.confirm'),
                icon: 'pi pi-book',
                routerLink: ['/order/demand/invoice']
              },
              {
                label: this.translate.instant('menu.replyDemandOrder.all'),
                icon: 'pi pi-calendar',
                routerLink: ['/order/demand/all']
              }
            ]
          },
          {
            label: this.translate.instant('menu.supplyInvoice'),
            /*icon: 'pi pi-book',*/
            items: [
              {
                label: this.translate.instant('menu.supplyInvoice.createSupplyInvoice'),
                icon: 'pi pi-book',
                routerLink: ['/order/supply_invoice/new']
              },
              {
                label: this.translate.instant('menu.supplyInvoice.myInvoice'),
                icon: 'pi pi-calendar',
                routerLink: ['/order/supply_invoice/my_list']
              }
            ]
          },
          {
            label: this.translate.instant('menu.replyDemandOrderGroup'),
            icon: 'pi pi-list',
            routerLink: ['order/demand/reply_demand_order_group']
          },
          {
            label: this.translate.instant('menu.supplyDemandOrderGroup'),
            icon: 'pi pi-book',
            routerLink: ['order/demand/supply_demand_order_group']
          },
          {
            label: this.translate.instant('menu.distributionInvoice'),
            icon: 'pi pi-arrows-alt',
            routerLink: ['/order/supply_invoice/distribution_invoice']
          },
          {
            label: this.translate.instant('menu.myDemandUser'),
            icon: 'pi pi-user',
            routerLink: ['/setting/my_demand_user']
          },
          {
            label: this.translate.instant('menu.message'),
            icon: 'pi pi-comment',
            routerLink: ['/message']
          }
        ]
      }
    ];
    
    this.model2 = [
      {
        label: this.translate.instant('menu.dashboard'),
        icon: 'pi pi-home',
        routerLink: ['/']
      },
      {
        label: this.translate.instant('menu.adminProduct'),
        icon: 'pi pi-book',
        routerLink: ['/admin/product/list']
      },
      {
        label: this.translate.instant('menu.adminAddProduct'),
        icon: 'pi pi-plus',
        routerLink: ['/admin/product/add']
      }
    ];
  }
}
